.container-flex-with__camera {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.tchat-container {
    padding: 10px 5px;
    position: relative;
}

.tchat-container .loader-spinner-message {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    background-color: #1a1a2ae8;
    color: white;
    padding: 10px;
    border-radius: 10px;
    top: 50%;
}

@media screen and (min-width: 901px) {
    .tchat-container .loader-spinner-message {
        left: calc(50% + 113px);
    }
}

.layout-tchat::-webkit-scrollbar {
    width: 0;
}

.layout-tchat {
    scrollbar-width: none;
    height: calc(100vh - 277px);
    overflow: auto;
    border: 1px solid rgb(240 240 240);
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.layout-tchat .item-message.sender {
    padding: 2px 0;
    display: flex;
    margin-right: 10px;
    flex-direction: row;
    justify-content: flex-end;
}

.layout-tchat .item-message.sender .link-message {
    color: white;
    text-decoration: underline;
    word-break: break-all;
}

.layout-tchat .item-message.sender .link-message:hover {
    color: orange;
}

.layout-tchat .item-message.receiver {
    padding: 2px 0;
    display: flex;
    margin-left: 10px;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
}

.layout-tchat .item-message.receiver .link-message {
    color: white;
    text-decoration: underline;
    word-break: break-all;
}

.layout-tchat .item-message.receiver .link-message:hover {
    color: orange;
}

.layout-tchat .content-item-tchat::after {
    content: attr(senderinfo);
    text-transform: lowercase;
    font-weight: bold;
    font-size: small;
    position: relative;
    left: 42px;
    top: -2px;
}

.layout-tchat .content-item-tchat-date {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    position: relative;
}

.layout-tchat .content-item-tchat-date span {
    background: white;
    z-index: 1;
    padding: 0 10px;
}

.layout-tchat .content-item-tchat-date::after {
    content: '';
    border-bottom: 1px solid #e4e6eb;
    position: absolute;
    width: 100%;
    bottom: 23px;
}

.layout-tchat .item-message.receiver .content-avatar {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
}

.layout-tchat .item-message.sender .content-avatar {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.layout-tchat .item-message.sender .content-box-message {
    background: #e4e6eb;
    padding: 2px 10px;
    border-radius: 25px;
    color: #050505;
}

.layout-tchat .sender .video-wrapper .video-frame {
    border: 0;
    border-radius: 10px;
    margin-top: -5px;
    margin-bottom: -12px;
    margin-right: -5px;
}

.layout-tchat .receiver .video-wrapper .video-frame {
    border: 0;
    border-radius: 10px;
    margin-top: -5px;
    margin-bottom: -12px;
    margin-left: -5px;
}

.layout-tchat .item-message.receiver .content-box-message {
    margin-left: 8px;
    background: #e4e6eb;
    padding: 2px 10px;
    border-radius: 25px;
    color: #050505;
    font-size: 14px;
    padding: 7px;
}

.flex-container form .ant-upload-list {
    display: none;
}

.layout-tchat .item-message.receiver .content-box-message.continue {
    margin-left: 32px;
    background: #e4e6eb;
    padding: 2px 10px;
    border-radius: 15px;
    color: #050505;
    font-size: 14px;
    padding: 7px;
}

.layout-tchat .item-message.sender .image-render-item.continue {
    padding: 7px 0 7px 0;
    border-radius: 15px;
    margin-left: 32px;
    max-width: 500px;
    width: 50%;
}

.layout-tchat .item-message.receiver .content-box-message.continue.continue-normalize {
    border-radius: 5px 15px 15px 5px !important;
    font-size: 14px;
    padding: 7px;
}

.layout-tchat .item-message.receiver .image-render-item.continue.continue-normalize {
    border-radius: 5px 15px 15px 5px !important;
    padding: 7px 0 0 7px;
    max-width: 500px;
    margin-left: 25px;
    width: 50%;
}

.layout-tchat .item-message.sender .content-box-message.continue.continue-normalize {
    border-radius: 15px 5px 5px 15px !important;
    font-size: 14px;
    padding: 0 7px;
    background: #096dd9;
    color: white;
}

.layout-tchat .item-message.sender .image-render-item.continue.continue-normalize {
    padding: 7px 0 7px 0;
    border-radius: 15px 5px 5px 15px !important;
    max-width: 500px;
    width: 50%;
}

.layout-tchat .item-message.receiver .content-box-message.continue:first-child {
    border-radius: 15px 15px 15px 5px;
    font-size: 14px;
    padding: 7px;
}

.layout-tchat .item-message.receiver .image-render-item.continue:first-child {
    border-radius: 15px 15px 15px 5px;
    padding: 7px 0 0 7px;
    max-width: 500px;
    margin-left: 25px;
    width: 50%;
}

.layout-tchat .item-message.sender .content-box-message.continue:first-child {
    border-radius: 15px 15px 5px 15px;
    font-size: 14px;
    padding: 7px;
    background: #096dd9;
    color: white;
}

.layout-tchat .item-message.sender .image-render-item.continue:first-child {
    border-radius: 15px 15px 5px 15px;
    font-size: 14px;
    padding: 7px 0 7px 0;
    max-width: 500px;
    width: 50%;
}

.layout-tchat .item-message.receiver .content-box-message.stop {
    border-radius: 5px 15px 15px 15px;
    padding: 7px;
}

.layout-tchat .item-message.receiver .image-render-item.stop {
    border-radius: 5px 15px 15px 15px;
    padding: 7px 0 0 7px;
    max-width: 500px;
    width: 50%;
}

.layout-tchat .item-message.receiver .content-box-message.stop p {
    font-size: 14px;
}

.layout-tchat .item-message.sender .content-box-message.stop {
    border-radius: 15px 5px 15px 15px;
    padding: 7px;
    background: #096dd9;
    color: white;
}

.layout-tchat .item-message.sender .image-render-item.stop {
    border-radius: 15px 5px 15px 15px;
    padding: 7px 0 7px 0;
    max-width: 500px;
    width: 50%;
}

.layout-tchat .item-message.sender .content-box-message.stop p {
    font-size: 14px;
}

@media screen and (min-width: 1281px) {
    .layout-tchat .item-message.sender .content-box-message {
        max-width: 60%;
    }
    .layout-tchat .item-message.receiver .content-box-message {
        max-width: 60%;
    }
}

@media screen and (max-width: 1280px) {
    .layout-tchat .item-message.sender .content-box-message {
        max-width: 80%;
    }
    .layout-tchat .item-message.receiver .content-box-message {
        max-width: 80%;
    }
}

@media screen and (min-width: 701px) {
    .layout-tchat .video-wrapper .video-frame {
        width: 500px;
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .layout-tchat .video-wrapper .video-frame {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .layout-tchat .item-message.sender .content-box-message {
        max-width: calc(100% - 42px);
    }
    .layout-tchat .item-message.receiver .content-box-message {
        max-width: calc(100% - 42px);
    }
}

.layout-tchat .item-message.sender .content-box-message p {
    padding: 0;
    margin: 0;
}

.layout-tchat .item-message.receiver .content-box-message p {
    padding: 0;
    margin: 0;
}