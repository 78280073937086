div.star {
  width: 2px;
  height: 2px;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.9;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  animation: linear infinite star-anim;
  opacity: 1;
}

@keyframes star-anim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.bigtwinkle {
  position: absolute;
  background: red;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
