.home-layout > .flex-container {
    display: flex;
    flex-direction: row;
}

.flex-container > section:first-child {
    flex: unset;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 64px);
    flex-direction: column;
}

.spinner-container span:nth-child(1) {
    font-size: 50px !important;
    margin-bottom: 10px;
}

.spinner-container span:nth-child(2) {
    color: white;
}