body, section {
    background: #1a1a2a !important;
    font-family: 'Ubuntu', sans-serif;
}

.global-layout.blur {
    transition: all 1s;
    filter: blur(2px)
}

.global-layout {
    transition: all 1s;
}