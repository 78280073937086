.ant-card-head {
    padding: 0 24px;
}

.ant-card-head-title {
    padding: 0;
}

.title-user-private-tchat {
    width: 100%;
}

.container-header-tchat {
    flex: 1;
}

.container-header-tchat .ant-card-extra {
    padding: 0;
}

.container-header-tchat .ant-card-extra .ant-avatar-circle {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
}

.container-header-tchat .flex-content {
    display: flex;
    align-items: center;
}

.container-header-tchat .flex-content div:first-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-height: 49px;
}

.container-header-tchat .flex-content div:first-child .dots-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.container-header-tchat .flex-content .container-group-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-header-tchat .flex-content .container-group-info ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: row;
    display: flex;
    margin-top: -4px;
}

.container-header-tchat .flex-content .container-group-info ul li:not(:first-child) {
    margin-left: -10px;
}

.container-header-tchat form .upload-image .ant-btn {
    border: none !important;
    outline: none;
    font-size: 20px;
}

.container-header-tchat form .upload-image .ant-btn:hover {
    color: rgb(38 38 38);
}

.container-header-tchat .flex-content .btn-drawer {
    padding: 0 10px;
    font-size: 23px;
    color: #001529;
}

.container-header-tchat .card-container-header-tchat {
    height: 100%;
    border-radius: 0;
}

.container-header-tchat .card-container-header-tchat.open {
    transition: .5s;
    animation: animationOpenRotate .5s;
}

.container-header-tchat .card-container-header-tchat.close {
    transition: .5s;
    animation: animationCloseRotate .5s;
}

.container-header-tchat>.communication-handle {
    display: flex;
    justify-content: space-between;
    width: 55px;
}

.container-header-tchat>.communication-handle button {
    border-radius: 5px;
    border: none;
}

.title-user-private-tchat>div>div:first-child {
    font-size: 15px;
    margin-left: 20px;
    margin-bottom: 0;
    padding-bottom: 5px;
    margin-top: 4px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.card-container-header-tchat .ant-card-head-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 0;
}

.title-user-private-tchat>div>div>p {
    padding: 0;
    margin: 0;
}

.form-col {
    width: calc(100% - 32px);
}

.form-col button {
    float: right;
}