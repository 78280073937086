.layout-login {
    height: 100vh;
    display: flex;
    background: rgb(2, 0, 36) !important;
    background: linear-gradient( 180deg, rgba(2, 0, 36, 1) 0%, rgba(11, 11, 94, 1) 20%, rgba(0, 212, 255, 1) 100%) !important;
}

.layout-content {
    overflow: scroll;
    z-index: 10;
}

.layout-content .picture-form img {
    width: 280px;
    margin-bottom: 1em;
}

.layout-login .layout-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.layout-login .layout-content .title-form {
    color: #f2f2f2f2;
}

.layout-login-form {
    display: flex;
    flex-direction: column;
    margin-top: calc(100vh - 95vh);
    max-width: 60%;
}

.layout-login-form .color-preview {
    width: 30px;
    height: 20px;
    border-radius: 5px;
    display: inline-block;
}

.github-picker {
    position: absolute !important;
    z-index: 1;
    width: 100% !important;
    top: -15px;
}

.layout-login-form label[for="color"] {
    color: #d9d9d9;
}

.layout-login-button {
    width: 150px;
    margin: auto;
}