.profile-container {
    background: #fff;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-wrap: wrap;
}

.profile-container .information, .parametre, .rgpd {
    flex-basis: 50%;
    flex-grow: 0;
    padding: 50px 100px;
}

@media screen and (max-width: 900px) {
    .profile-container {
        height: 100vh;
        overflow: auto;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 80px;
    }
    
    .profile-container .information, .parametre, .rgpd {
        flex-basis: 100%;
        flex-grow: 1;
        padding: 20px;
    }
}

.profile-container .information .info-list {
    padding-top: 20px;
    margin-bottom: -20px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.profile-container .information .info-list .untouched {
    background: #f7f7f7;
    padding: 5px 8px;
    border-radius: 3px;
}

.profile-container .avatar-container {
    display: flex;
    align-items: center;
}

.profile-container .avatar-container .btn-change-image {
    display: flex;
    flex-direction: column;
}

.profile-container .avatar-container .btn-change-image .ant-btn-danger {
    margin-top: 5px;
}

.profile-container .avatar-container .upload-image .ant-upload-list {
    display: none;
}

.profile-container .avatar-container .avatar-preview {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.profile-container .parametre .info-list {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.profile-container .parametre .info-list .status, .langue {
    display: inline-flex;
    align-items: center;
}

.profile-container .parametre .info-list .status span, .langue span {
    margin-right: 10px;
}

.profile-container .parametre .info-list button {
    display: block;
    min-width: 100px;
}

.profile-container .delete-profile button {
    display: block;
}

.container-profile .avatar {
    text-align: center;
    margin-bottom: 1em;
}

.container-profile .flex-container {
    display: flex;
    justify-content: space-between;
}

.container-profile .friend-request {
    text-align: center;
}

.container-profile .friend-request .friendly {
    background-color: rgb(1, 196, 1);
    border: greenyellow;
}

.container-profile .avatar .ant-avatar-lg {
    width: 100px;
    height: 100px;
    line-height: 100px;
}