.nav-bar-header {
    display: flex;
    justify-content: space-between;
    background: #4a86e8 !important;
}

.nav-bar-header-login-form {
    margin-top: 10px;
    display: inline-flex;
    background: #1a1a2a !important;
}

.nav-bar-header-login-form .ant-form-item-control-input-content {
    display: flex;
}

.nav-bar-header-login-form .ant-form-item-control-input-content .ant-input-affix-wrapper {
    margin-right: 10px;
}

.nav-bar-header .user-action>span:hover {
    cursor: pointer;
}

.nav-bar-header .user-action {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.nav-bar-header .user-action .wrapped-element-user {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-bar-header .user-action .ant-badge {
    margin-right: 15px !important;
}

.nav-bar-header .user-action .wrapped-element-user .bell-button svg {
    font-size: 25px;
    color: rgba(255, 255, 255, 0.911);
}

.avatar-icon {
    /* background: rgb(245, 106, 0) !important; */
}

.avatar-icon>span {
    text-transform: uppercase;
}

.dropdown-menu-title {
    text-align: center !important;
}

.dropdown-menu-title .option {
    display: flex;
    justify-content: space-between;
    margin-left: 12px;
    margin-right: 12px;
}

.modal__settings .ant-modal-content {
    background: rgb(229, 247, 250);
}

.modal__settings .ant-modal-content .ant-modal-header {
    background: rgb(229, 247, 250);
}